@use "./styles/containers.scss" as *;
@use "./styles/widths.scss" as *;
@use "./styles/gaps.scss" as *;
@use "./styles/margins.scss" as *;
@use "./styles/paddings.scss" as *;

@import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-balham.css";
@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

*::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #AEB6BF;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.owl-dt-container {
  font-size: 0.75rem; /* here size can be handled*/
}
.owl-dt-inline-container {
  box-shadow: none !important;
}
.owl-dt-shadow {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
}
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

// body,
// html {
//   height: 100%;
// }

// .btn-primary {
//   color: #fff;
//   background-color: #1b6ec2;
//   border-color: #1861ac;
// }

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* .mat-list-item-content{
  padding: 0 !important;
} */

// .main-content {
//   padding: 12px;
// }

.active-list-item {
  color: azure !important;
  background-color: #3f51b5 !important;
}
.row {
  display: flex;
  margin: 6px;
  padding: 6px;
  vertical-align: bottom;
}

.col {
  flex: 1;
  margin: 0 1rem;
}

.mat-header-cell {
  flex-direction: column;
  justify-content: center;
  vertical-align: middle;
}

.mat-cell {
  vertical-align: middle;
}

.table-row {
  height: 30px !important;
}

/* Snackbar */
.snackbar-config {
  max-width: 53vw !important;
}

.snackbar-success {
  background: #a5d6a7;
  color: #0e5849;
}
.snackbar-success .mat-button-wrapper {
  color: #004d40;
}

.snackbar-error {
  background: #ffccbc;
  color: #b15959;
}

.snackbar-error .mat-button-wrapper {
  color: #b71c1c;
}

.snackbar-warning {
  background: #f8c471;
  color: #884d25;
}

.snackbar-warning .mat-button-wrapper {
  color: #6e2c00;
}

.snackbar-neutral {
  background: #b3e5fc;
  color: #3743be;
}

.snackbar-neutral .mat-button-wrapper {
  color: #1a237e;
}
/* color: #535389; */

.total-red-line {
  background-color: lightcoral !important;
}

.mat-button-toggle-label-content {
  line-height: 30px !important;
}

.composite > div:first-child {
  padding-left: 30px;
}

.nestedComposite > div:first-child {
  padding-left: 50px;
}

.ag-header-cell.asc::before {
  content: "↑";
}
.ag-header-cell.desc::before {
  content: "↓";
}
.ag-header-cell.asc::before,
.ag-header-cell.desc::before {
  position: absolute;
  top: 0;
  right: 10px;
  height: 20px;
  width: 8px;
  background: transparent;
  font-size: 15px;
}

.last-of-section {
  /* costing-sheet */
  border-right: 1px solid #a3a3a3 !important;
}

.mat-tooltip {
  font-size: 12px;
}

.mat-radio-button {
  margin: -1px 4px !important;
}

.mat-radio-label-content {
  padding-left: 4px !important;
}

span.mat-radio-label-content {
  font-size: 15px;
}



.ag-root-wrapper {
  //border-color: var(--ag-border-color, #1976d2) !important;
  border-radius: 4px;
  //border: none !important;
}

.ag-theme-balham .ag-header {
  border-bottom: none !important;
  text-transform: uppercase;
  background-color: #eceff1;
  font-size: 11px;
}

.ag-theme-balham .ag-header-cell-text {
  color: rgba(0, 0, 0, 0.87) !important;

  // font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-button {
  line-height: 32px !important;
  padding: 0 10px !important;
}

.mat-raised-button {
  line-height: 32px !important;
  padding: 0 10px !important;
}
