.gap-4 {
  gap: 4px;
}

.gap-6 {
  gap: 6px;
}

.gap-8 {
  gap: 8px;
}

.gap-16 {
  gap: 16px;
}

.gap-24 {
  gap: 24px;
}
