.d-flex {
  display: flex;
}

.d-flex-center {
  @extend .d-flex;
  justify-content: center;
  align-items: center;
}

.d-flex-vert-center {
  @extend .d-flex;
  align-items: center;
}

.d-flex-vert-base {
  @extend .d-flex;
  align-items: baseline;
}

.d-flex-vert-end {
  @extend .d-flex;
  align-items: flex-end;
}

.d-flex-end {
  @extend .d-flex;
  justify-content: flex-end;
  align-items: center;
}

.d-flex-sp-btw {
  @extend .d-flex;
  justify-content: space-between;
}

.d-flex-column {
  @extend .d-flex;
  flex-direction: column;
}

.d-flex-row {
  @extend .d-flex;
  flex-direction: row;
}

.d-flex-wrap {
  @extend .d-flex;
  flex-wrap: wrap;
}

.d-flex-col-end {
  @extend .d-flex-column;
  align-items: flex-end;
}

.d-flex-vert-end {
  @extend .d-flex;
  align-items: flex-end;
}

.d-flex-hor-center {
  @extend .d-flex;
  justify-content: center;
}
