//top
.mg-t4 {
  margin-top: 4px;
}

.mg-t8 {
  margin-top: 8px;
}

.mg-t16 {
  margin-top: 16px;
}

.mg-t24 {
  margin-top: 24px;
}

.mg-t32 {
  margin-top: 32px;
}

//right
.mg-r4 {
  margin-right: 4px;
}

.mg-r8 {
  margin-right: 8px;
}

.mg-r16 {
  margin-right: 16px;
}

.mg-r24 {
  margin-right: 24px;
}

.mg-r32 {
  margin-right: 32px;
}

//bottom
.mg-b4 {
  margin-bottom: 4px;
}

.mg-b8 {
  margin-bottom: 8px;
}

.mg-b16 {
  margin-bottom: 16px;
}

.mg-b24 {
  margin-bottom: 24px;
}

.mg-b32 {
  margin-bottom: 32px;
}


//left
.mg-l4 {
  margin-left: 4px;
}

.mg-l8 {
  margin-left: 8px;
}

.mg-l16 {
  margin-left: 16px;
}

.mg-l24 {
  margin-left: 24px;
}

.mg-l32 {
  margin-left: 32px;
}
